'use client'

import type { Tables } from '@/types_db'
import { getStripe } from '@/utils/stripe/client'
import { checkoutWithStripe } from '@/utils/stripe/server'
import { getErrorRedirect } from '@/utils/helpers'
import { useAuth } from '@clerk/nextjs'
import { useRouter, usePathname } from 'next/navigation'
import { useState } from 'react'

type Subscription = Tables<'subscriptions'>
type Product = Tables<'products'>
type Price = Tables<'prices'>
interface ProductWithPrices extends Product {
  prices: Price[]
}
interface PriceWithProduct extends Price {
  products: Product | null
}
interface SubscriptionWithProduct extends Subscription {
  prices: PriceWithProduct | null
}

interface Props {
  products: ProductWithPrices[]
  subscription: SubscriptionWithProduct | null
}

type BillingInterval = 'lifetime' | 'year' | 'month'

export default function Pricing({ products, subscription }: Props) {
  const intervals = Array.from(
    new Set(
      products.flatMap((product) =>
        product?.prices?.map((price) => price?.interval)
      )
    )
  )
  const router = useRouter()
  const [billingInterval, setBillingInterval] =
    useState<BillingInterval>('month')
  const [priceIdLoading, setPriceIdLoading] = useState<string>()
  const currentPath = usePathname()
  const { isSignedIn } = useAuth()

  const handleStripeCheckout = async (price: Price) => {
    setPriceIdLoading(price.id)

    if (!isSignedIn) {
      setPriceIdLoading(undefined)
      return router.push('/app/sign-in')
    }

    const { errorRedirect, sessionId } = await checkoutWithStripe(
      price,
      currentPath
    )

    if (errorRedirect) {
      setPriceIdLoading(undefined)
      return router.push(errorRedirect)
    }

    if (!sessionId) {
      setPriceIdLoading(undefined)
      return router.push(
        getErrorRedirect(
          currentPath,
          'An unknown error occurred.',
          'Please try again later or contact a system administrator.'
        )
      )
    }

    const stripe = await getStripe()
    stripe?.redirectToCheckout({ sessionId })

    setPriceIdLoading(undefined)
  }

  if (!products.length) {
    return (
      <div className="max-w-6xl px-4 py-8 mx-auto">
        <p>
          No subscription pricing plans found. Create them in your{' '}
          <a
            className="text-aredeorange hover:underline"
            href="https://dashboard.stripe.com/products"
            rel="noopener noreferrer"
            target="_blank">
            Stripe Dashboard
          </a>
          .
        </p>
      </div>
    )
  } else {
    // Filter out products named "Test"
    const visibleProducts = products.filter(
      (product) => product.name !== 'Test'
    )

    return (
      <div className="py-8 sm:py-20 sm:px-80 bg-aredegray mx-auto rounded-3xl">
        <div className="flex flex-col align-center">
          <h2 className="text-center">Choose Your Plan</h2>
          <p className="max-w-2xl m-auto px-4 mt-5 text-xl sm:text-center">
            One pricing model, simplicity at it&apos;s core.
          </p>
          <div className="relative self-center mt-6 flex space-x-2">
            {intervals.includes('month') && (
              <button
                onClick={() => setBillingInterval('month')}
                type="button"
                className={`w-32 py-2 px-4 text-center transition-colors ${
                  billingInterval === 'month'
                    ? 'bg-aredeblack text-aredewhite rounded-3xl'
                    : 'bg-aredewhite text-aredegraytext border border-aredegrayborder rounded-3xl'
                }`}>
                Monthly
              </button>
            )}
            {intervals.includes('year') && (
              <button
                onClick={() => setBillingInterval('year')}
                type="button"
                className={`w-32 py-2 px-4 text-center transition-colors relative ${
                  billingInterval === 'year'
                    ? 'bg-aredeblack text-aredewhite rounded-3xl'
                    : 'bg-aredewhite text-aredegraytext border border-aredegrayborder rounded-3xl'
                }`}>
                Yearly
                <span className="absolute -top-1 -right-1 bg-aredeorange text-aredewhite font-semibold text-xs px-2 py-1 rounded-full -mt-2 -mr-2">
                  Save 17%
                </span>
              </button>
            )}
          </div>
        </div>
        <div className="mt-12 space-y-0 sm:mt-16 flex flex-wrap justify-center gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0">
          {/* Plans */}
          {visibleProducts.map((product) => {
            const price = product?.prices?.find(
              (price) => price.interval === billingInterval
            )
            if (!price) return null
            const priceString = new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: price.currency!,
              minimumFractionDigits: 0,
            }).format((price?.unit_amount || 0) / 100)
            return (
              <div
                key={product.id}
                className={`flex flex-col rounded-3xl bg-aredewhite border border-aredegrayborder divide-y flex-1 basis-1/3 max-w-xs ${
                  subscription
                    ? product.name === subscription?.prices?.products?.name
                      ? ''
                      : ''
                    : ''
                }`}>
                <div className="flex flex-col p-6 flex-grow">
                  <h3 className="text-2xl">{product.name}</h3>
                  <p className="mt-1">{product.description}</p>
                  <div className="flex-grow"></div>
                  <p className="mt-8">
                    <span className="text-3xl font-extrabold">
                      {priceString}
                    </span>
                    <span className="text-base font-medium">
                      /{billingInterval}
                    </span>
                  </p>
                  <button
                    type="button"
                    onClick={() =>
                      isSignedIn
                        ? handleStripeCheckout(price)
                        : router.push('/app/sign-in')
                    }
                    disabled={priceIdLoading === price.id || !!subscription}
                    className={`btn-primary w-full mt-4 ${
                      priceIdLoading === price.id || !!subscription
                        ? 'opacity-50 cursor-not-allowed'
                        : ''
                    }`}>
                    {isSignedIn
                      ? subscription
                        ? 'Current Plan'
                        : 'Upgrade'
                      : 'Continue'}
                  </button>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}
