'use client'

import { ButtonAuth } from './buttonAuth'

export default function CTA() {
  return (
    <div className="px-4 pb-32">
      <div className="max-w-7xl mx-auto md:text-center md:px-8">
        <div className="max-w-xl space-y-3 md:mx-auto">
          <p className="font-semibold text-aredeorange">Begin</p>
          <h2>Start Growing in Public</h2>
          <p>
            Setup in less than 5 minutes. Start growing your portfolio in
            public.
          </p>
        </div>
        <div className="mt-4 flex justify-start md:justify-center">
          <ButtonAuth />
        </div>
      </div>
    </div>
  )
}
