'use client'

import Image from 'next/image'
import { ButtonAuth } from './buttonAuth'
import { useState, useEffect, useMemo } from 'react'

export default function Hero() {
  const [investmentType, setInvestmentType] = useState('Stocks')

  const investmentTypes = useMemo(
    () => ['Stocks', 'Crypto', 'ETFs', 'Property', 'Business', 'Startup'],
    []
  )

  useEffect(() => {
    const intervalId = setInterval(() => {
      setInvestmentType((prevType) => {
        const currentIndex = investmentTypes.indexOf(prevType)
        const nextIndex = (currentIndex + 1) % investmentTypes.length
        return investmentTypes[nextIndex]
      })
    }, 2000)

    return () => clearInterval(intervalId)
  }, [investmentTypes])

  return (
    <div className="relative px-4 pb-14 sm:py-24">
      <div className="mx-auto max-w-7xl">
        <div className="flex items-center justify-between">
          <div className="max-w-2xl">
            <div className="text-left">
              <h1 className="sm:text-6xl mt-8">
                Grow Your{' '}
                <span className="inline-block w-[200px] transition-opacity duration-400 text-aredeorange">
                  {investmentType}
                </span>
                <br /> In Public.
              </h1>
              <p className="mt-6 text-lg leading-8">
                Share your portfolio with the world. Create a beautiful
                portfolio page, add your stocks, and track your performance.
              </p>
              <div className="mt-10 grid grid-cols-2 gap-4 sm:grid-cols-4 mb-6">
                <div className="bg-aredegray p-4 rounded-lg text-center">
                  <p className="text-2xl font-bold text-blue-600">10+</p>
                  <p className="text-sm text-aredeblack">Supported Indices</p>
                </div>
                <div className="bg-aredegray p-4 rounded-lg text-center">
                  <p className="text-2xl font-bold text-green-600">$1M+</p>
                  <p className="text-sm text-aredeblack">Assets Tracked</p>
                </div>
                <div className="bg-aredegray p-4 rounded-lg text-center">
                  <p className="text-2xl font-bold text-aredeorange">12K+</p>
                  <p className="text-sm text-aredeblack">Supported Stocks</p>
                </div>
                <div className="bg-aredegray p-4 rounded-lg text-center">
                  <p className="text-2xl font-bold text-purple-600">24/7</p>
                  <p className="text-sm text-aredeblack">Support</p>
                </div>
              </div>
              <div className="flex items-center gap-x-6 mt-4">
                <ButtonAuth />
              </div>
            </div>
          </div>
          <div className="hidden lg:block">
            <Image
              src="/images/dashboard-hero.svg"
              alt="Portfolio growth illustration"
              width={500}
              height={500}
              className="rounded-3xl"
            />
          </div>
        </div>
      </div>
    </div>
  )
}
