'use client'

import * as Accordion from '@radix-ui/react-accordion'
import Image from 'next/image'

export default function FAQ() {
  const faqCategories = [
    {
      category: 'Getting Started',
      faqs: [
        {
          question: 'Is there a trial period?',
          answer: "No, we don't offer a trial period. You can cancel anytime.",
        },
        {
          question: 'Is there a limit to the number of profiles I can create?',
          answer: 'A user can create one profile.',
        },
        {
          question: 'How often do you release updates?',
          answer:
            'We typically release minor updates and bug fixes weekly. Major feature updates are released monthly. All updates are automatically applied to your account.',
        },
      ],
    },
    {
      category: 'Security and Privacy',
      faqs: [
        {
          question: 'Is my data safe?',
          answer:
            'Yes, your data is secure. The only thing we store which is not public, is your email address. Payments are handled by Stripe which is extremely secure.',
        },
        {
          question: 'How do you handle data backups?',
          answer: 'Data is backed up periodically.',
        },
        {
          question: 'Can I request my data to be deleted?',
          answer:
            'Absolutely. As per GDPR requirements, you can request a full deletion of your data at any time. Simply contact us at support@publicgains.me, and we will process your request within 30 days, providing you with confirmation once completed.',
        },
      ],
    },
    {
      category: 'Plans and Pricing',
      faqs: [
        {
          question: 'What plans do you offer?',
          answer:
            'We offer one plan: Plus (7.99€/month) or 80€/year which you save up to 17%.',
        },
        {
          question: 'What are my payment options?',
          answer:
            'All major credit and debit cards. We use Stripe for payments.',
        },
        {
          question: 'Is there a refund policy?',
          answer:
            "We offer a 30-day money-back guarantee for all new subscriptions. If you're not satisfied with our service within the first 30 days, you can request a full refund, no questions asked.",
        },
      ],
    },
  ]

  return (
    <div className="px-4 py-4">
      <div className="mx-auto max-w-7xl">
        <div className="max-w-2xl mx-auto mb-20">
          <h2 className="text-center">Frequently Asked Questions</h2>
        </div>

        <div className="max-w-2xl mx-auto">
          {faqCategories.map((category, catIndex) => (
            <div key={catIndex} className="mb-10">
              <h3 className="text-aredeblack font-semibold mb-4">
                {category.category}
              </h3>
              <Accordion.Root type="single" collapsible>
                {category.faqs.map((faq, faqIndex) => (
                  <Accordion.Item
                    key={faqIndex}
                    value={`item-${catIndex}-${faqIndex}`}
                    className="mb-4 bg-aredegray rounded-3xl py-2 px-4">
                    <Accordion.Header>
                      <Accordion.Trigger className="flex justify-between w-full py-2 text-left text-aredeblack">
                        <span className="text-aredeblack font-normal text-base">
                          {faq.question}
                        </span>
                        <Image
                          src="/icons/chevronD.svg"
                          width={16}
                          height={16}
                          alt="Expand/Collapse"
                          className="transform transition-transform duration-200 data-[state=open]:rotate-180"
                        />
                      </Accordion.Trigger>
                    </Accordion.Header>
                    <Accordion.Content className="py-2 text-aredegraytext overflow-hidden">
                      {faq.answer}
                    </Accordion.Content>
                  </Accordion.Item>
                ))}
              </Accordion.Root>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
